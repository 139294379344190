import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const ServicesPage = () => (
  <Layout>
    <Seo title="Office" />
    <HeroMini title="Office"/>
    <main className="section has-text-black">
  <div className="container">
  <div className="columns">
  <div className="column">
    <p className="mb-6">Depend on our full-spectrum of consultation services to provide the ingenuity you need to solve your challenges on time and within budget.</p>
  </div>
  </div>

  {/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">HTML</p>
  </div>
  </div> */}

  <div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Office 365</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/wired/64/000000/office-365.png"/>
    <p>With a subscription to Microsoft 365, you can get: The latest Office apps, like Word, Excel, PowerPoint, and Outlook. The ability to install on PCs, Macs, tablets, and phones. 1 TB of OneDrive cloud storage. </p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Power Apps</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/fluent/48/000000/microsoft-power-apps-2020.png"/>
    <p>Power Apps is a suite of apps, services, connectors and data platform that provides a rapid application development environment to build custom apps for your business needs. Using Power Apps, you can quickly build custom business apps that connect to your business data stored either in the underlying data platform (Microsoft Dataverse) or in various online and on-premises data sources (SharePoint, Microsoft 365, Dynamics 365, SQL Server, and so on). </p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Elastic Search</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/elasticsearch.png"/>
    <p>Elastic Search is a scalable open source search engine and database that has been gaining popularity among developers building cloud-based systems. When suitably configured, it is capable of ingesting and efficiently querying large volumes of data very rapidly. It’s reasonably straightforward to build and deploy an Elasticsearch cluster to Azure. </p>
  </div>
</div>

<div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Sharepoint Search</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/ms-share-point.png"/>
    <p>Search in SharePoint includes a wide variety of improvements and new features. With this version, Search in SharePoint is re-architected to a single enterprise search platform. The search architecture consists of the following areas: 1. Crawl and content processing 2. Index 3. Query processing 4. Search administration 5. Analytics </p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Sharepoint</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/dusk/64/000000/ms-share-point.png"/>
    <p>With Microsoft SharePoint on your PC, Mac, or mobile device, you can: Build intranet sites and create pages, document libraries, and lists. Add web parts to customize your content. Show important visuals, news, and updates with a team or communication site. Discover, follow, and search for sites, files, and people across your company. </p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont"></p>
 
  </div>
</div>
{/* 
<div className="columns">
  <div className="column content">
  <p className="is-size-2">React</p>
  </div>
  </div> */}


</div>
</main>
  </Layout>
)

export default ServicesPage
